class ConsolidateStatusService {
  constructor () {
    this.STATUS_CLOSED = 0
    this.STATUS_PROGRESS = 1

    this.TYPE_NEW = 0
    this.TYPE_FORMED = 1
    this.TYPE_AGREED = 2
  }
}

export default new ConsolidateStatusService()
