import Vue from 'vue'

class WeekService {
  constructor () {
    this.STATUS_SKIP = 0
  }

  async getAll (fields = null, projectId = null) {
    try {
        let url = '/week?'
        if (fields) {
          url += 'fields=' + fields
        }
        if (projectId) {
          url += 'project_id=' + projectId
        }
        return (await Vue.prototype.$http.get(url)).data
    } catch (error) {
        return []
    }
  }

  async get (id) {
    try {
      return (await Vue.prototype.$http.get(`/week/${id}`)).data
    } catch (error) {
      return {}
    }
  }
}

export default new WeekService()
