import Vue from 'vue'
import ConsolidateStatusService from './ConsolidateStatusService'

class ProjectService {
    constructor () {
        this.ROLE_ADMIN = 0
        this.ROLE_DIRECTOR = 1
        this.ROLE_EMPLOYEE = 2
        this.ROLE_CLIENT = 3

        this.MONDAY_NUM = 1
        this.TUESDAY_NUM = 2
        this.WEDNESDAY_NUM = 3
        this.THURSDAY_NUM = 4
        this.FRIDAY_NUM = 5

        this.USER_STATUS_NEW = 0
        this.USER_STATUS_ACTIVATED = 1

        this.STATUS_ACTIVE = 1
        this.STATUS_ARCHIVE = 0
    }

    async getAll () {
        try {
            return (await Vue.prototype.$http.get('/projects')).data
        } catch (error) {
            return []
        }
    }

    delete (id) {
      return Vue.prototype.$http.delete(`/projects/${id}`)
    }

    getRolename (role) {
      let roleName = ''

      switch (parseInt(role)) {
        case this.ROLE_EMPLOYEE:
          roleName = 'Сотрудник'
          break
        case this.ROLE_DIRECTOR:
          roleName = 'Руководитель'
          break
        case this.ROLE_CLIENT:
          roleName = 'Заказчик'
          break
        case this.ROLE_ADMIN:
          roleName = 'Администратор'
          break
        default:
          break
      }
      return roleName
    }

    getCollectionTimeString = function (collectionStartTime, collectionStartDay) {
      let result = ''

      switch (collectionStartDay) {
        case this.MONDAY_NUM:
          result += 'каждый понедельник'
          break
        case this.TUESDAY_NUM:
          result += 'каждый вторник'
          break
        case this.WEDNESDAY_NUM:
          result += 'каждую среду'
          break
        case this.THURSDAY_NUM:
          result += 'каждый четверг'
          break
        case this.FRIDAY_NUM:
          result += 'каждую пятницу'
          break
        default:
          break
      }

      result += ` ${collectionStartTime} по мск.`
      return result
    }

    getEmployees = function (project, onlyActive = false) {
      const employees = []
      for (const user of project.users) {
        if (parseInt(user.role) === this.ROLE_EMPLOYEE) {
          if (onlyActive) {
            if (parseInt(user.status) === this.USER_STATUS_ACTIVATED) {
              employees.push(user)
            }
          } else {
            employees.push(user)
          }
        }
      }

      return employees
    }

    getClients = function (project, onlyActive = false) {
      const clients = []
      for (const user of project.users) {
        if (parseInt(user.role) === this.ROLE_CLIENT) {
          if (onlyActive) {
            if (parseInt(user.status) === this.USER_STATUS_ACTIVATED) {
              clients.push(user)
            }
          } else {
            clients.push(user)
          }
        }
      }

      return clients
    }

    getDirector = function (project) {
      return project.users.find((user) => parseInt(user.role) === this.ROLE_DIRECTOR)
    }

    getAdmin = function (project) {
      return project.users.find((user) => parseInt(user.role) === this.ROLE_ADMIN)
    }

    getNumAgeedStatuses = function (project) {
      let numAgreedStatuses = 0

      for (const week of project.weeks) {
        for (const status of week.statuses) {
          if (parseInt(status.type) === ConsolidateStatusService.TYPE_AGREED) {
            numAgreedStatuses += 1
          }
        }
      }

      return numAgreedStatuses
    }
  }
  export default new ProjectService()
