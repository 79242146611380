import ConsolidateStatusService from './ConsolidateStatusService'

class AnswerService {
  constructor () {
    this.STATUS_CLOSED = 0
    this.STATUS_PROGRESS = 1
    this.STATUS_FINISHED = 2

    this.TYPE_COMPLETED = 0
    this.TYPE_FUTURE = 1
    this.TYPE_PROBLEM = 2
  }

  getAnswers (week, type, userId = null) {
    const answers = []
    for (const status of week.statuses) {
      if (status.type !== ConsolidateStatusService.TYPE_FORMED) {
        continue
      }
      for (const answer of status.answers) {
        if (userId && userId !== answer.user_id) {
          continue
        }

        if (answer.answers_text.length === 0) {
          continue
        }
        for (const answerText of answer.answers_text) {
          if (answerText.type === type) {
            let text = ''
            text = answerText.text
            answers.push(text)
          }
        }
      }
    }

    return answers
  }

  getUserAnswer (answer, user) {
    let answerText = ''
    const completedRaws = []
    const futureRaws = []
    const problemsRaws = []
    const textAnswers = answer.answers_text

    if (textAnswers.length === 0) {
      return `Сотрудник ${user.username}:\n\nНе работал на этой неделе\n\n`
    }

    for (const textAnswer of textAnswers) {
      let completedTextRaws = []
      let futureTextRaws = []
      let problemTextRaws = []

      switch (parseInt(textAnswer.type)) {
        case this.TYPE_COMPLETED:
          completedTextRaws = textAnswer.text.split('\n')
          for (const raw of completedTextRaws) {
            completedRaws.push(raw)
          }
          break
        case this.TYPE_FUTURE:
          futureTextRaws = textAnswer.text.split('\n')
          for (const raw of futureTextRaws) {
            futureRaws.push(raw)
          }
          break
        case this.TYPE_PROBLEM:
          problemTextRaws = textAnswer.text.split('\n')
          for (const raw of problemTextRaws) {
            problemsRaws.push(raw)
          }
          break
        default:
          break
      }
    }

    const completedTask = '- ' + completedRaws.join(' \n- ')
    const futureTask = '- ' + futureRaws.join(' \n- ')
    const problems = '- ' + problemsRaws.join(' \n- ')
    answerText += `Сотрудник ${user.username}:\n\n ✅ Выполненные задачи:\n\
${completedTask}\n\
\n\
🗓 Планы на следующий период:\n\
${futureTask}\n\
\n\
‼️ Проблемы:\n\
${problems}\n\n`

    return answerText
  }
}

export default new AnswerService()
